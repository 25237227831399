@font-face {
    font-family: "Chivo";
    src: local("Chivo"), url("../fonts/Chivo/Chivo-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "ChivoBold";
    src: local("ChivoBold"), url("../fonts/Chivo/Chivo-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "ChivoBlack";
    src: local("ChivoBlack"), url("../fonts/Chivo/Chivo-Black.ttf") format("truetype");
}