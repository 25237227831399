@import "./assets/css/font.css";

body {
  margin: 0;
  font-family: Chivo, 'Open Sans', sans-serif;
  letter-spacing: 0.03em;
}

p {
  font-weight: 400;
  font-size: 16px;
  color: #100f17;
  margin: 0 0 10px 0;
  line-height: 170%;
}

.p-24 {
  padding: 24px !important;
}

code {
  font-family: Chivo, 'Open Sans', sans-serif;
}
